'use client'

import Link from 'next/link'

export default function NotFound() {
  return (
    <div className="from-brand-dark-bg to-brand-dark-surface col-[bleed-start/bleed-end] grid min-h-screen grid-cols-subgrid bg-gradient-to-b px-6 py-24 sm:py-32 lg:px-8">
      <div className="col-[content-start/content-end] max-w-2xl place-self-center text-center text-gray-100">
        {/* 404 Icon/Illustration */}
        <div className="mb-8 flex justify-center">
          <div className="relative">
            <div className="bg-gradient-to-r from-yellow-400 to-amber-500 bg-clip-text text-[10rem] leading-none font-extrabold text-transparent select-none sm:text-[12rem]">
              404
            </div>
            <div className="absolute -bottom-4 h-4 w-full bg-gradient-to-r from-yellow-400/10 to-amber-500/10 blur-2xl"></div>
          </div>
        </div>

        {/* Error message */}
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          Page not found
        </h1>
        <p className="mb-8 text-lg leading-7 text-gray-400">
          Sorry, we couldn't find the page you're looking for.
        </p>

        {/* Navigation options */}
        <div className="flex flex-col justify-center gap-4 sm:flex-row">
          <Link
            href="/"
            className="bg-brand-gold hover:bg-brand-gold/90 rounded-md px-6 py-3 text-sm font-semibold text-black shadow-sm transition-all focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
          >
            Back to home
          </Link>
          <button
            onClick={() => window.history.back()}
            className="rounded-md bg-gray-700/50 px-6 py-3 text-sm font-semibold text-white shadow-sm ring-1 ring-gray-600/80 transition-all ring-inset hover:bg-gray-700/80"
          >
            Go back
          </button>
        </div>
      </div>
    </div>
  )
}
